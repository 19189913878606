import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './services/i18n';
import InsightLandingPage from './InsightLandingPage';
import * as serviceWorker from './serviceWorker';

import {CookiesProvider} from 'react-cookie';
import {applyPolyfills, defineCustomElements} from 'grower-component/loader';
import * as Sentry from '@sentry/browser';

Sentry.init({dsn: `${process.env.REACT_APP_SENTRY_DNS}`});
console.log(`${process.env.REACT_APP_SENTRY_DNS}`);

ReactDOM.render(
    <CookiesProvider>
        <InsightLandingPage/>
    </CookiesProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

applyPolyfills().then(() => {
    defineCustomElements(window)
});

import React from "react";
import './AppTeaset.css'
import graph from '../../assets/graph.svg'
import imagery from '../../assets/imagery.svg'
import action from '../../assets/action.svg'
import {withNamespaces} from "react-i18next";

const AppTeaser = ({ onDownloadAppClick, onContinueClick, isContinueEnabled, t }) => {
    const renderContinueButton = () => {
        if (!isContinueEnabled) {
            return null;
        }

        return <div className='app-teaser-continue' onClick={onContinueClick}>
            {t('appTeaser.goToMobileWeb')}
        </div>
    };

    return <div className='app-teaser-container'>
        <div className='app-teaser-headline'>
            {isContinueEnabled ? t('appTeaser.insightHeadline') : t('appTeaser.reportHeadline')}
        </div>
        <div className='app-teaser-separator'/>
        <div className='app-benefit-section'>
            <div className='app-benefit-options'>
                <img src={graph} alt=""/>
                <div className='app-benefit-text'>
                    {t('appTeaser.benefitOne')}
                </div>
            </div>
            <div className='app-benefit-options'>
                <img src={imagery} alt=""/>
                <div className='app-benefit-text'>
                    {t('appTeaser.benefitTwo')}
                </div>
            </div>
            <div className='app-benefit-options'>
                <img src={action} alt=""/>
                <div className='app-benefit-text'>
                    {t('appTeaser.benefitThree')}
                </div>
            </div>
        </div>
        <div className='download-app-button-container' onClick={onDownloadAppClick}>
            <div className='download-app-button-text'>
                {isContinueEnabled ? t('continue') : t('appTeaser.reportDownloadAppButtonText')}
            </div>
        </div>
        {renderContinueButton()}
    </div>
};

export default withNamespaces()(AppTeaser)

import React from 'react';
import logo from './assets/prospera-logo.svg'
import './InsightLandingPage.css';
import InsightViewer from './components/InsightViewer';
import InsightProvider from "./services/InsightProvider";
import AppLoader from "./components/Loader";
import toaster from "toasted-notes";
import {Toast} from "./components/Toast";
import {AnimateOnChange} from 'react-animation'
import AppTeaser from "./components/appTeaser/AppTeaser";
import {Cookies, withCookies} from 'react-cookie';
import {getInsightUid, getUserUid} from "./services/RegexExtractor";
import downloadAppLinkProvider from "./services/DownloadAppLinkProvider";
import copy from 'copy-to-clipboard';
import InsightDetailModelMapper from "./models/InsightDetailModelMapper";
import BlurredFieldImage from './assets/blurred-field.png';
import {withI18n, withNamespaces} from "react-i18next";
import {momentInit} from "./services/MomentConfig";

const TOAST_AUTO_CLOSE_DURATION = 3 * 1000;

const ZOOM_PAN_OPTIONS = {
    viewportMargins: {
        left: 50,
        right: 50,
        top: 10,
        bottom: 110
    }
};

const VI_COOKIE_NAME = 'vi-app-teser';
const VI_COOKIE_CONTENT = '';

class InsightLandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.insightProvider = new InsightProvider(props.t);
        this.cookies = new Cookies();

        momentInit();

        this.state = {
            ready: false,
            imageViewerImage: null,
            insightDetail: null,
            tags: null,
            isAppTeaserVisible: false
        };
    }

    closeAppTeaser = () => {
        if (!this._isInsightTypeSupported()) {
            return;
        }
        this.setState({isAppTeaserVisible: false})
    };

    async componentDidMount() {
        const insight = await this.insightProvider.provider(getInsightUid(window.location.pathname), getUserUid(window.location.pathname));
        this.insight = insight;

        if (this.cookies.get(VI_COOKIE_NAME) == null || !this._isInsightTypeSupported()) {
            this.setState({isAppTeaserVisible: true});
            this._setCookieFor(1)
        }

        this.setState({
            imageViewerImage: insight?.imageViewerImage,
            insightDetail: insight?.insightDetail,
            tags: insight?.tags,
            ready: !!insight
        });
    }

    onShareClick = async () => {
        const { t } = this.props;
        console.log(this.state);
        const { insightDetail: { shareDate, fieldName, insightSubject, uid } } = this.state;

        const shareText = t(
            'insight.shareInsight',
            {
                date: shareDate,
                field: fieldName,
                subject: insightSubject,
                url: `${process.env.REACT_APP_LANDING_PAGE_URL}${uid}`
            }
        );
        copy(shareText);

        toaster.notify(() => <Toast toastText={t('copiedToClipboard')}/>, {
            duration: TOAST_AUTO_CLOSE_DURATION
        });
    };

    _renderMockFieldImage() {
        return <div className='field-image-mock'>
            <img src={BlurredFieldImage} alt='Valley Insights'/>
        </div>;
    }

    _getImageViewerImage() {
        const { t } = this.props;
        const { imageViewerImage } = this.state;
        return {
            ...imageViewerImage,
            type_desc: t(imageViewerImage.type_desc)
        }
    }

    _renderInsightViewer() {
        const { i18n } = this.props;
        return <div className='insight-viewer'>
            { !this._isInsightTypeSupported()
              ? this._renderMockFieldImage()
              : <InsightViewer is-share-button-enabled={true}
                               is-share-text-shown={false}
                               publicAssetPath="/static"
                               imageViewerImage={this._getImageViewerImage()}
                               insightDetail={this.state.insightDetail} tags={this.state.tags}
                               zoomPanOptions={ZOOM_PAN_OPTIONS} onShareCallback={this.onShareClick}
                               should-show-tags={true}
                               language={i18n.language}/> }
        </div>
    }

    render() {

        if (!this.state.ready) {
            return <AppLoader/>
        }
        return (
            <div className="landing-page-container">
                {this.state.isAppTeaserVisible ?
                    <div className='app-teaser-background' onClick={this.closeAppTeaser}/> : null}
                {this.state.isAppTeaserVisible ? this._getAppTeaser() : null}

                <header className="vi-header">
                    <img src={logo} alt=""/>
                </header>

                { this._renderInsightViewer() }

                <footer className="vi-footer" id='vi-footer-id'>
                    <div className="get-app-text">
                        {this.props.t('getValleyApp')}
                    </div>
                    <div className="get-vi-app-button" onClick={this._redirectToAppStore}>
                        <div className="footer-text blue1-text">{this.props.t('download')}</div>
                    </div>
                </footer>
                {this._showFootAfter(2000)}
            </div>
        )
    }

    _showFootAfter(ms) {
        setTimeout(() => {
            document.getElementById('vi-footer-id').style.visibility = "visible"
        }, ms)
    }

    _isInsightTypeSupported() {
        return InsightDetailModelMapper.isInsightTypeSupported(this.insight?.insightDetail?.type);
    }

    _getAppTeaser() {
        return <div style={{height: 0}}>
            <AnimateOnChange>{this.state.isAppTeaserVisible ?
                <AppTeaser onContinueClick={this.closeAppTeaser}
                           isContinueEnabled={this._isInsightTypeSupported()}
                           onDownloadAppClick={this._redirectToAppStore}/> : null}</AnimateOnChange>
        </div>
    }

    _setCookieFor(days) {
        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + days);

        this.cookies.set(VI_COOKIE_NAME, VI_COOKIE_CONTENT, {expires: expireDate});
    }

    _redirectToAppStore() {
        const insightUid = getInsightUid(window.location.pathname);
        const url = downloadAppLinkProvider(insightUid);
        console.info('Download url', url);
        window.location.href = url;
        // window.open(downloadAppLinkProvider(), "_blank");
    }

}

export default withI18n()(
    withNamespaces()(
        withCookies(InsightLandingPage)
    )
);

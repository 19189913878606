import getMainImage from "../services/GetMainImage";
import moment from "moment-timezone";

export default class InsightDetailModelMapper {
    _translate;

    map(serverModel, translateCallback) {
        this._translate = translateCallback;

        const mainImage = getMainImage(serverModel);
        const { time_zone, type } = serverModel;
        const insightDetail = { type };

        if (InsightDetailModelMapper.isInsightTypeSupported(type)) {
            serverModel.hasOwnProperty('uid') ? insightDetail.uid = serverModel.uid : console.warn('`uid` field does not exist on server model');
            serverModel.hasOwnProperty('farm_name') ? insightDetail.farmName = serverModel.farm_name : console.warn('`farm_name` field does not exist on server model');
            serverModel.hasOwnProperty('field_name') ? insightDetail.fieldName = serverModel.field_name : console.warn('`field_name` field does not exist on server model');

            if (mainImage?.hasOwnProperty('date')) {
                insightDetail.imageTakenDate = this._getImageTakenDate(mainImage.date._seconds, time_zone);
                insightDetail.shareDate = this._getImageTakenDate(mainImage.date._seconds, time_zone, 'date.short');
            } else {
                console.warn('`date` field does not exist on server model');
            }

            serverModel.hasOwnProperty('subject')
                ? insightDetail.insightSubject = this._getTranslatedDynamicText(serverModel.i18n_subject, serverModel.subject)
                : console.warn('`subject` field does not exist on server model');

            serverModel.hasOwnProperty('description')
                ? insightDetail.description = this._getTranslatedDynamicText(serverModel.i18n_description, serverModel.description)
                : console.warn('`description` field does not exist on server model');

            serverModel.hasOwnProperty('transformed_tags_area')
                ? insightDetail.affectedArea = this._getAffectedArea(serverModel.transformed_tags_area, serverModel.area_unit)
                : console.warn('`transformed_tags_area` field does not exist on server model');
        }

        return insightDetail;
    }

    static isInsightTypeSupported(type) {
        return !type || ['default', 'irrigation'].includes(type);
    }

    _getImageTakenDate(seconds, timezone, formatKey = 'date.fullWithTz') {
        const date = new Date(seconds * 1000);
        return moment(date).tz(timezone).format(this._translate(formatKey));
    }

    _getTranslatedDynamicText(translatedObj, defaultText) {
        return translatedObj ? this._translate(
            translatedObj.token,
            {
                ...(translatedObj.params || {}),
                defaultValue: defaultText
            }
        ) : defaultText;
    }

    _getAffectedArea(affectedArea, areaUnit) {
        const area = affectedArea.toFixed(2);
        const unit = this._translate(areaUnit.token);
        const params = { area, unit };
        return this._translate(
            'insight.affectedAreaLabel',
            params
        );
    }

}

import axios from 'axios';
import InsightModelMapper from "../models/InsightModelMapper";
import InsightDetailModelMapper from "../models/InsightDetailModelMapper";
import TagsModelMapper from "../models/TagsModelMapper";

export default class InsightProvider {
    insightModelMapper = new InsightModelMapper();
    insightDetailModelMapper = new InsightDetailModelMapper();
    tagsModelMapper = new TagsModelMapper();

    constructor(translateCallback) {
        this._translateCallback = translateCallback;
    }

    async provider(insightUid, userUid) {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${insightUid}`, this._getRequestConfiguration(userUid));
            return {
                imageViewerImage: this.insightModelMapper.map(response.data),
                insightDetail: this.insightDetailModelMapper.map(response.data, this._translateCallback),
                tags: this.tagsModelMapper.map(response.data),
            }
        } catch(error) {
            console.error(error);
        }
    }

    _getRequestConfiguration(userUid) {
        return userUid == null ? null : {
            headers: {
                Authorization: `uid ${userUid}`
            }
        }
    }
}

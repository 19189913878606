import getMainImage from "../services/GetMainImage";

const TYPE_TO_DISPLAY_NAME = {
    rgb: 'imageLayerData.rgb.name',
    ndvi: 'imageLayerData.ndvi.name',
    thermal: 'imageLayerData.thermal.name'
}

export default class InsightModelMapper {

    map(serverModel) {
        const mainImage = getMainImage(serverModel);
        return mainImage ? this._mapImages(mainImage) : null;
    }

    _mapImages(imagesServerModel) {
        const images = {};
        imagesServerModel.hasOwnProperty('image_id') ? images.image_id = imagesServerModel.image_id : console.warn('image_id filed does not exist on server model');

        if (imagesServerModel.hasOwnProperty('type')){
            images.type = imagesServerModel.type;
            images.type_desc = this._mapTypeToDisplayName(imagesServerModel.type);
        }
        else {
            console.warn('type filed does not exist on server model');
        }

        imagesServerModel.hasOwnProperty('bounds') ? images.bounds = imagesServerModel.bounds : console.warn('bounds filed does not exist on server model');

        if (imagesServerModel.hasOwnProperty('preview')) {
            images.preview = [];
            imagesServerModel.preview.forEach(preview => {
                images.preview.push(this._mapImageLevels(preview))
            })
        }
        return images;
    }

    _mapImageLevels(imageLevelServerModel) {
        const imageLevel = {};
        imageLevelServerModel.hasOwnProperty('url') ? imageLevel.url = imageLevelServerModel.url : console.warn('url filed does not exist on server model');
        imageLevelServerModel.hasOwnProperty('width') ? imageLevel.width = imageLevelServerModel.width : console.warn('width filed does not exist on server model');
        imageLevelServerModel.hasOwnProperty('height') ? imageLevel.height = imageLevelServerModel.height : console.warn('height filed does not exist on server model');
        if (imageLevelServerModel.hasOwnProperty('colormap')) {
            imageLevel.colormap = imageLevelServerModel.colormap;
        }
        return imageLevel;
    }

    _mapTypeToDisplayName(type) {
        if (!TYPE_TO_DISPLAY_NAME.hasOwnProperty(type)) {
            console.warn('type filed does not exist on server model');
        }
        return TYPE_TO_DISPLAY_NAME[type];
    }
}
